<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pagamento',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'Aguardando pagamento',
      'Payment confirmed': 'Pagamento confirmado',

      'Address': 'Endereço',
      'Send exactly value to make payment': 'Envie exatamente o valor para efetuar o pagamento',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pago',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'En espera de pago',
      'Payment confirmed': 'Pago confirmado',

      'Address': 'Dirección',
      'Send exactly value to make payment': 'Enviar exactamente el valor de hacer el pago',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        voucher: false,
        date: null,

        subtotal: 'R$ 0,00',
        discount: 'R$ 0,00',
        shipping: {
          method: '',
          value: 'R$ 0,00',
          address: {
            zipcode: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            state: '',
          }
        },
        total: 'R$ 0,00',

        items: null,
        status: null,

        payment: {
          name: 'MercadoPago',
          status: 'pending',
          total: null,
          address: null,
          qrcode: null,
          expiration: null,
        }
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get('store/orders/'+this.order.id)
          .then(response => {
            if (response.data.status=='success') {
              this.order = response.data.order
              this.loading = false
            } else {
              this.empty = true
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.errored = error
          })
      }
    },
    getFile: function () {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
    setFile() {
      this.document.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          appConfig.apiUrl + "store/orders/file/" + this.order.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getFile();
            this.$noty.success(response.data.message);
            this.document.status = false;
          } else {
            this.$noty.error(response.data.message);
            this.document.status = false;
          }
          this.document.loading = false;
        });
    },
    onFileSelected(e) {
      this.file = e.target.files[0]
    },
  },
  mounted() {
    this.getOrder()
    this.getFile()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Order') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty">{{ t('No records found.') }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">
                  #{{ order.id }}
                </h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0 text-uppercase">{{ t('Item') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Price') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Qty') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Total') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td,index) in order.items" :key="index">
                      <td>{{ td.name }} </td>
                      <td>{{ td.price }}</td>
                      <td>{{ td.quantity }}</td>
                      <td>{{ td.subtotal }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3 class="font-size-16 mb-3">{{ t('Endereço de entrega') }}</h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement">{{ order.shipping.address.complement }}, </span>
                    {{ order.shipping.address.district }}<br>
                    {{ order.shipping.address.city }},
                    {{ order.shipping.address.state }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <!-- <a v-if="order.shipping.tracking.code" target="_blank" :href="order.shipping.tracking.url" class="btn btn-dark btn-sm mt-1 p-2 align-middle">
                    <i class="bx bxs-truck font-size-17 text-white mr-1 align-top"></i>
                    Rastrear pedido
                  </a> -->
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">{{ t('Detalhes do pagamento') }}</h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.discount != 'R$ 0,00'">
                          <td class="px-0 py-1">{{ t('VOUCHER') }}</td>
                          <td class="px-0 py-1 text-right">- {{ order.discount }}</td>
                        </tr>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t('SUBTOTAL') }}</td>
                          <td class="px-0 py-1 text-right">{{ order.subtotal }}</td>
                        </tr>
                        <tr v-if="order.shipping.value != 'R$ 0,00'">
                          <td class="px-0 py-1">{{ t('FRETE') }} — {{ order.shipping.method }}</td>
                          <td class="px-0 py-1 text-right">+ {{ order.shipping.value }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">{{ t('TOTAL') }}</td>
                          <td class="px-0 py-1 font-weight-bold text-right">{{ order.total }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t('Status') }}</h3>
              <div v-for="(td,index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                <b-tab v-for="(pay,method) in order.payment.methods" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method == 'mercadopago'" class="text-center">
                    <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-mercadopago.svg">
                    <a target="_blank" class="btn btn-success btn-block btn-lg" :href="pay.url">{{ t('Click here to pay') }}</a>
                  </div>
                  <div v-if="method == 'pix'" class="text-center">
                    <img style="width: 250px" :src="pay.qrcode" />
                    <p>Leia o qrcode acima ou</p>
                    <ol class="text-left">
                      <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                      <li>Busque a opção de pagar com pix.</li>
                      <li>Copie e cole o seguinte código.</li>
                      <li>
                        Após o pagamento envie o comprovante da tranferência.
                      </li>
                    </ol>

                    <p class="text-left">
                      A confirmação do pagamento pode demorar até 10 minutos
                      entre os horários das 07:00 as 00:00 horas, após este
                      período caso não receba seus tokens entrar em contato com
                      o suporte através de um ticket. Pagamentos realizados
                      entre 00:00 as 07:00 serão confirmados a partir das 07:00
                      horas.
                    </p>

                    <div class="border rounded p-3 mb-3 text-left">
                      {{ pay.copypaste }}
                    </div>

                    <button
                      class="btn btn-default"
                      v-clipboard:copy="pay.copypaste"
                    >
                      <i class="bx bx-copy font-size-18 align-middle"></i>
                      Copiar
                    </button>

                    <hr class="" />
                    <div class="mt-5 text-center">
                      <h3 class="font-size-16 mb-2">Comprovantes</h3>
                      <p>
                        Após realizar seu pagamento, anexe seu comprovante aqui.
                      </p>
                      <div
                        class="pb-3"
                        v-for="(prof, index) in proof.list"
                        :key="index"
                      >
                        <a target="_blank" :href="prof.url"
                          >
                          {{ prof.url.replace("https://s3.us-west-2.amazonaws.com/s3.designogold.io/store/", "") }}</a
                        >
                      </div>
                      <div class="text-center p-0 row justify-content-center mb-5">
                        <b-form @submit.prevent="setFile" class="col-m-8 col-lg-6">
                          <div class="input-group">
                            <input
                              type="file"
                              id="file"
                              ref="file"
                              aria-describedby="inputGroupFileAddon04"
                              @change="onFileSelected"
                              aria-label="Upload"
                              class="form-control"
                            />
                            <b-button
                              class="border-left-0 px-4"
                              style="
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                              "
                              type="submit"
                              variant="default"
                              :disabled="document.loading"
                            >
                              {{ t("Send") }}
                              <b-spinner
                                v-if="document.loading"
                                small
                                class="ml-2 align-middle"
                                variant="white"
                                role="status"
                              ></b-spinner>
                            </b-button>
                          </div>
                        </b-form>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>